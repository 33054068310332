<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >


    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='type' label='分类'>
        <el-select v-model="form.data.type" placeholder="请选择分类">
          <el-option
            v-for="item in typeOpt"
            :key="item.id"
            :label="item.value"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop='title' label='标题'>
        <el-input v-model="form.data.title" placeholder="请输入标题20个字以内" maxlength="20" clearable></el-input>
      </el-form-item>
      <el-form-item prop='content' label='说明' class="textareaBox">
        <el-input
          type="textarea"
          placeholder="请输入说明200个字以内"
          v-model="form.data.content"
          maxlength="200"
          show-word-limit
          :autosize="{ minRows: 4, maxRows: 10}"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop='video' label='图片/视频'>
        <el-upload
          class="avatar-uploader-image"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest">
          <template v-if="form.data.video">
            <video v-if="imgType == 'video'" :src="form.data.video" controls height="130px" width="230px"></video>
            <img v-else :src="form.data.video" class="avatar">
          </template>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'GuideEdit',
    props: ['typeOpt','roleType'],
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            type: '',
            title: '',
            content: '',
            role_type: '',
            video: '',
          },
          rules: {
            type: [{ required: true, message:'请选择分类', trigger: 'change' }],
            title: [{ required: true, message:'请输入标题', trigger: 'change' }],
            content: [{ required: true, message: '请输入说明', trigger: 'change' }],
          }
        },
        fileList: [],
        imgType: '',
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.$http.get('admin/flex_guide/info', {params:{id: row.id} }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
            if(res.data.video.includes('.mp4') || res.data.video.includes('.mp3') ) {
              this.imgType = 'video'
            }else {
              this.imgType = ''
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            let apiUrl = !this.isChange ? '/admin/flex_guide/add' : '/admin/flex_guide/edit'
            this.form.data.role_type = this.roleType;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      // 上传视频
      httpRequest(file) {
        this.loading = true;
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.video = res.data.all_path_url;
          if(res.data.name.includes('.mp4')) {
            this.imgType = 'video'
          }else {
            this.imgType = ''
          }
          this.$refs.elFormDom.clearValidate();
        }).finally(() => {
          this.loading = false;
        })
      },
      handleRemove(file) {
        this.fileList = []
      }
    }
  }
</script>

<style scoped>
.textareaBox ::v-deep .el-input__count {
  color: #909399;
  background: none !important;
  position: absolute;
  font-size: 12px;
  bottom: 0;
  right: 10px;
}
.avatar-uploader-image .el-icon-plus {
  width: 230px;
  height: 130px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-image .avatar {
  width: 230px;
  height: 130px;
}
.avatar-uploader-image .avatar-uploader-icon{
  line-height: 130px !important;
}
</style>
